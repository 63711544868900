
.left-section-en,
.right-section-en {
  z-index: 1;
}

.right-section-en{
  max-width: 700px;
}

.right-section-en h2 ,
.right-section-en p {
  text-align: left;
}

.right-section-en h2 {
  font-size: 35px;
  color: var(--main-color);
}


.right-section-en p{
  color: #000000ba;
  font-size: 16px;
  max-width: 600px;
}



.right-section-en button {
  color: white;
  padding: 10px 30px;
}


.left-section-en{
  max-width: 450px;
  
}



@media (max-width: 1336px) {
  .left-section-en{
    max-width: 400px;
  }

  .right-section-en{
    max-width: 550px;
  }

  .right-section-en h2{
    font-size: 40px;
  }
  .right-section-en p {
    
  }
}

@media (max-width: 1100px) {
  .left-section-en{
    max-width: 300px;
  }
  .right-section-en h2{
    font-size: 36px;
  }
  .right-section-en p {
    font-size: 15px;
  }

  .right-section-en button {
    font-size: 12px;

  }
  .right-section-en{
    max-width: 500px;
  }

}

@media (max-width: 920px) {
  .left-section-en{
    max-width: 270px;
  }
  .right-section-en h2{
    font-size: 30px;
  }
  .right-section-en p {
    font-size: 13px;
  }

  .right-section-en button {
    font-size: 12px;
  }

  .right-section-en{
    max-width: 380px;
  }
}

@media (max-width: 800px) {

  .left-section-en{
    max-width:280px ;
    order: 1;
  }

  .right-section-en{
    padding:  30px;
    order: 2;
  }

  .right-section-en h2 {
    font-size: 25px;
 
  }

  .right-section-en p {
    font-size: 10px;
    
  }

  .button-container {
    justify-content: center;
  }

  .right-section-en button {
    font-size: 12px;
  }
}

@media (max-width: 460px) {

  .left-section-en{
    max-width:280px ;
    padding-top: 50px;
    text-align: center;
  }


  .right-section-en{
    padding: 20px;
  }

  .right-section-en h2 {
    font-size: 22px;
    
  }

  .right-section-en p {
    font-size:10px;
    
  }

  .right-section-en button {
    font-size: 12px;
  }
}



/* Add these animations to your About.css */

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-left {
  animation: fadeInFromLeft 1s forwards;
}

.fade-in-right {
  animation: fadeInFromRight 1s forwards;
}

.fade-in-top {
  animation: fadeInFromTop 1s forwards;
}

.hidden {
  opacity: 0;
}

