@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Readex+Pro:wght@160..700&display=swap');

.readex-pro {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "HEXP" 0;
}

.cairo-c {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

@font-face {
  font-family: 'somar';
  src: url('./Assets/Fonts/somar.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:lang(ar), 
body :lang(ar) {
  direction: rtl;
  font-family: "somar", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body:lang(en), 
body :lang(en) {
  direction: ltr;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --main-color:#32aac2;
  --second-color:rgb(33, 133, 139);
  --main-gradient:linear-gradient(90deg, #32aac2 0%,#3a849f 100%);
  --background-gradient:linear-gradient(90deg, rgb(5, 44, 49) 0%, rgb(10, 77, 80) 50%, rgb(5, 44, 49) 100%);
  --background-color:rgb(231, 231, 231);

}

h1{
  font-weight: 500;
  background: var(--main-gradient);
  color: transparent; 
  -webkit-background-clip: text; 
  background-clip: text; 
}

h2{
  font-weight: 500;
}

h3{
  font-weight: 500;
  background: var(--main-gradient);
  color: transparent; 
  -webkit-background-clip: text; 
  background-clip: text; 
}

a{
  text-decoration: none;
}

button{
  padding: 10px 40px;
  font-size: 1rem;
  border-radius: 25px;
  color: #fff;
  background:var(--main-gradient);
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: 0.4s ease;
  font-weight: 400;
}

button:hover{
  background: linear-gradient(90deg, #3a8e9f 0%,#3a849f 100%);;
  border: transparent;
  cursor: pointer;
  color: white;
}

.word {
  position: relative;
  display: inline-block;
}

.word::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: var(--main-color); /* Background color */
  z-index: -1;
  transition: height 0.3s ease; /* Smooth transition */
}

.word:hover::before {
  height: 100%; /* Adjust the height as needed */
}

@media (max-width: 920px) {
  button{
    padding: 8px 20px;
  }
}