/* Services.css */

.Services {
  text-align: center;
  padding: 0 0;
  padding-bottom: 50px;
  background: var(--background-color);
  
}


.Services-Title {
  font-size: 1.5em;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-bottom: 10px;
  color: var(--main-color);
  padding-top: 55px;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.Services-Title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 2px; /* Adjust the height of the line */
  background: var(--main-color); /* Adjust the color of the line */
  
}

.Services-Description {
  font-size: 1.0em;
  color: #666;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding:  25px 0;
}

.Services-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 0 100px;
}

.Service-Box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.174);
  transition: 0.3s ease;
  cursor: pointer;
  min-height: 100%;
}

.Service-Box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 18px rgba(1, 66, 80, 0.699);
  background:var(--main-color);

}

.Service-Box:hover p,.Service-Box:hover h3,.Service-Box:hover .Service-Icon {
  color: white ;
}

.Service-Upper {
  padding:0 20px;
  padding-top:10px ;
}

.Service-Lower {
  padding: 10px;
  padding-bottom: 20px;
}

.Service-Icon {
  font-size: 40px; 
  color: var(--main-color); 
  margin-bottom: 10px; 
  
}

.Service-Lower h3 {
  font-size: 1.1em;
  margin: 10px 0;
  background: var(--main-gradient);
  color: transparent; 
  -webkit-background-clip: text; 
  background-clip: text; 
}

.Service-Lower p {
  font-size: 0.7em;
  color: #555;
  margin: 0;
  font-weight: 500;

}

.Button-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

}


.filter-buttons{
  margin:  50px 0;
  
  justify-content: center;
  
}

.filter-buttons button{
  margin: 0 10px;
  padding: 8px 30px;
  font-size: 15px;
  transition: 0.3s ease;
}

.filter-buttons button.active {
  background: linear-gradient(90deg, #254a52 0%, rgb(18, 77, 80) 50%, #254a52 100%);
}



@media (max-width: 768px) {
  .Services-Title{
    font-size: 1.21em;
  }
  .Services-Grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
    gap: 20px;
  }

  .Service-Box h3 {
    font-size: 0.8em;
    margin: 5px 0;
  }

  .Service-Box p{
    font-size: 8px;
  }
  .Service-Icon {
    font-size: 20px;
    margin-bottom: 3px; 
  }
  .Services-Title::after {
    width: 100px;
  }
  
  .Services-Description {
    font-size: 0.7em;
  }

  .Learn-More-Button {
    
    font-size: 0.7rem;
  }

  .filter-buttons button{
    font-size: 10px;
    padding: 1.5% 3%;
    margin: 0 5px;
  }
}

@media (max-width: 360px) {
    
    .Services-Title::after {
      width: 50px;
    }
    .Service-Box h3 {
      font-size: 0.7em;
      
    }
    .filter-buttons button{
      font-size: 9px;
    
    }
  }



/* Define the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Initial hidden state */
.fade-in {
  opacity: 0;
  animation: fadeIn 3s forwards; /* Adjust duration as needed */
}

/* Delay for different elements */
.fade-in-1 {
  animation-delay: 1s;
}
.fade-in-2 {
  animation-delay: 1s;
}
.fade-in-3 {
  animation-delay: 1.3s;
}
.fade-in-4 {
  animation-delay: 1.5s;
}
.fade-in-5 {
  animation-delay: 1.8s;
}
.fade-in-6 {
  animation-delay: 2s;
}

/* Define the initial hidden state */
.hidden {
  opacity: 0;
  transform: translateY(50px); /* Optional: slight upward movement */
  transition: opacity 2s ease-out, transform 1s ease-out;
}

/* Define the visible state */
.visible {
  opacity: 1;
  transform: translateY(0);
}
