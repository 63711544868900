

/* Body */
.Join-us-body {
  padding: 80px 20px;
  
}

/* Title Container */
.Page-title-container {
  text-align: center; /* Horizontally center the content */
  margin-bottom: 30px; /* Adjust as needed */
}

/* Title */

.Join-us-title{
  font-size: 30px;
}

.Join-us-description {
  font-size: 16px;
  color: #666; /* Adjust color as needed */
}

.title {
  font-size: 2.2em;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-bottom: 10px;
  color: var(--main-color);
  overflow: hidden;
}

/* Description */



/* Flex Container */
.flex-container {
  display: flex;
  margin: 0 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden; /* Ensure the image doesn't overflow its container */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Image */
.image-container img {
  width: 100%; /* Ensure the image fills the container width */
  height: 100%; /* Ensure the image fills the container height */
  object-fit: cover; /* Cover the entire container while maintaining aspect ratio */
}

/* Form */
.Join-us-form {
  flex: 1;
  padding:30px 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff; 
  
}

/* Form Row */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

/* Form Group */
.form-group {
  flex: 1;
  position: relative;
  margin-bottom: 20px;
  
}

/* Form Group: Input, Select, and Textarea */
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #ccc;
  background: none;
  outline: none;
  transition: 0.3s ease;
  
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-bottom-color: var(--main-color);
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group select:focus + label,
.form-group select:not(:placeholder-shown) + label,
.form-group textarea:focus + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -20px;
  font-size: 12px;
  color: var(--main-color);
  
}

/* Form Group: Label */
.form-group label {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #aaa;
  pointer-events: none;
  transition: all 0.3s ease;
  
}

.form-group input:not(:focus):placeholder-shown + label,
.form-group select:not(:focus):placeholder-shown + label,
.form-group textarea:not(:focus):placeholder-shown + label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #aaa;
}

/* Submit Button */
.submit-button {
  padding: 5px 290px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  
}

/* Responsive Design */
@media (max-width: 900px) {

  .Join-us-title{
    font-size: 20px;
  }
  
  .Join-us-description {
    font-size: 12px;
    color: #666; /* Adjust color as needed */
  }
  
  .flex-container {
    flex-direction: column-reverse;
    margin:0 5px;
  }

  .image-container {
    border-radius: 0;
  }
  .image-container img {
    border-radius: 0;
  }

  .form-group {
    margin-top: 10px;
    border-radius: 0;
  }
  .submit-button {
    padding: 5px 0;
    width: 100%;
    
  }
  
  .title {
    font-size: 1.55rem;
    font-weight: 500;
  }

  .description {
    font-size: 15px;
  }
}

@media (max-width: 338px) {
  .flex-container {
    
    margin:0 0px;
  }
}