
.left-section-en,
.right-section-en {
  width: 45%;
}

.left-section-en img,
.right-section-en img {
  width: 100%;
  border-radius: 15px;

}


.right-section-en h1,
.right-section-en p,
.left-section-en h1,
.left-section-en p {
  text-align: left;
}


.right-section-en p {
  color: #000000ba;
  font-size: 15px;
}

.right-section-en h1{
  font-size: 2rem;
  margin-bottom: 10px;
}

.right-section-en p ,
.right-section-en h1{
  text-align: left;

}


.dropdown-title-en {
  position: relative;
  
}

.dropdown-title-en h3 {
  cursor: pointer;
  margin: 0;
  font-weight:400px ;
  font-size: 15px;
  margin-bottom: 5px;
}

.dropdown-title-en h3::before {
  content: '⚫'; /* Unicode character for a downward arrow */
  font-size: 10px;
  margin-right: 2px;
  transition:  0.3s ease-in-out;
}

.dropdown-title-en h3::after {
  content: '▼'; /* Unicode character for a downward arrow */
  font-size: 12px;
  margin-left: 2px;
  transition: transform 0.3s ease-in-out;
}



.dropdown-title-en:hover .dropdown-content {
  max-height: 100px; /* Adjust as needed */
  opacity: 1;
  
}



/* Responsive styles */
@media (max-width: 800px) {


  .left-section-en img,
  .right-section-en img {
  width: 70%;
  border-radius: 15px;
}

  .left-section-en h1,
  .right-section-en h1{
    font-size: 25px;
  }

  .dropdown-title-en h3 {
    font-size: 13px;
  }


  .left-section-en h1,
  .right-section-en h1,
  .About-us-description {
    width: 100%;
    text-align: start;
  }

  .left-section-en,
  .right-section-en {
    width: 90%;
  }
  
  .A-two .right-section-en{
    order: 2;
    
  }
  
  .A-two .left-section-en{
    order: 1;
  }
}

