
/* Flex Container */
.flex-container-con-en {
  display: flex;
  margin: 0 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}


.contact-details-en {
  list-style: none;
  padding: 0;
}

.contact-details-en li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 0;
  color: #f6f6f6;
}

.contact-details-en li span {
  margin-left: 10px;
}



/* Responsive Design */
@media (max-width: 768px) {



  .flex-container-con-en {
    flex-direction: column;
    margin: 0 5px;
  }

}

@media (max-width: 338px) {
  .flex-container-con-en {
    margin: 0;
  }
}
