*{
  scroll-behavior: smooth;
  transition: 0.3s ease;
}

.Home{
  transition: 0.5s ease;
  
}

