
/* Flex Container */
.flex-container-en {
  display: flex;
  margin: 0 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container-en {
  border-radius: 0;
}

.image-container-en {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden; /* Ensure the image doesn't overflow its container */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Image */
.image-container-en img {
  width: 100%; /* Ensure the image fills the container width */
  height: 100%; /* Ensure the image fills the container height */
  object-fit: cover; /* Cover the entire container while maintaining aspect ratio */
}

/* Form */
.Join-us-form-en {
  flex: 1;
  padding:30px 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff; 
  
}



/* Form Group */
.form-group-en {
  flex: 1;
  position: relative;
  margin-bottom: 20px;
  
}

/* Form Group: Input, Select, and Textarea */
.form-group-en input,
.form-group-en select,
.form-group-en textarea {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #ccc;
  background: none;
  outline: none;
  transition: 0.3s ease;
  
}

.form-group-en input:focus,
.form-group-en select:focus,
.form-group-en textarea:focus {
  border-bottom-color: var(--main-color);
}

.form-group-en input:focus + label,
.form-group-en input:not(:placeholder-shown) + label,
.form-group-en select:focus + label,
.form-group-en select:not(:placeholder-shown) + label,
.form-group-en textarea:focus + label,
.form-group-en textarea:not(:placeholder-shown) + label {
  top: -20px;
  font-size: 12px;
  color: var(--main-color);
  
}

/* Form Group: Label */
.form-group-en label {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #aaa;
  pointer-events: none;
  transition: all 0.3s ease;
  
}

.form-group-en input:not(:focus):placeholder-shown + label,
.form-group-en select:not(:focus):placeholder-shown + label,
.form-group-en textarea:not(:focus):placeholder-shown + label {
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #aaa;
}


/* Responsive Design */
@media (max-width: 908px) {
  .flex-container-en {
    flex-direction: column-reverse;
    margin:0 5px;
  }

  .image-container-en img {
    border-radius: 0;
  }

  .form-group-en {
    margin-top: 10px;
    border-radius: 0;
  }
 

}

@media (max-width: 338px) {
  .flex-container-en {
    
    margin:0 0px;
  }
}