.Offers {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center;
  padding: 10px 0;
  padding-bottom: 30px;
  background: var(--background-color);
  
}

.Offers-Title {
  font-size: 1.5em;
  margin-bottom: 10px;
  position: relative;
  font-weight: 500;
  padding: 20px 0;
  color: var(--main-color);
}

.Offers-Title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 2px; 
  background: var(--main-color); 
}

.Offers-Description {
  font-size: 1.0em;
  color: #666;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Offers-Slider-section {
  width: 90%; /* Adjust width as needed */
}

@media (max-width: 508px) {
  .Offers-Title{
    font-size: 1.2em;
  }

  .Offers-Description {
    font-size: 0.7em;
  }

  .Offers-Title::after {
    width: 100px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 10px; /* Adjust the width as desired */
    height: 10px; /* Adjust the height as desired */

  }
  .Offers-Slider-section {
    width: 90%; /* Adjust width as needed */
  }

  .Offers-Title {
    padding: 0 0;
  }
}

@media (max-width: 375px) {
  .Offers-Title::after {
    width: 50px;
  }
}