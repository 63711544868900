

.Blog-Body-en {
  padding: 30px 250px;
  /* direction: rtl; */
}

.Blog-Grid-en {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1000px, 1fr));
  gap: 20px;
  
}

.Blog-Card-en {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.6s ease;
}

.Blog-Card-en:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Blog-Image-en {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.Blog-Content-en {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: 0.6s ease;
}

.Blog-Title-en {
  font-size: 1.5em;
  margin: 0 0 15px;
  flex-shrink: 0;
}

.Blog-Time-en {
  color: #999;
  font-size: 0.9em;
  margin: 0 0 10px;
  flex-shrink: 0;
}

.Blog-Description-en {
  font-size: 1em;
  margin: 0;
  color: #000000a1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 4.5em; /* 1.5em * 3 lines */
  transition: max-height 0.6s ease;
}

.Blog-Description-en.expanded {
  -webkit-line-clamp: unset;
  max-height: none; /* Allow full height */
}

.Read-More-en {
  background: none;
  border: none;
  color: var(--main-color);
  cursor: pointer;
  padding: 10px 0;
  text-align: right;
  font-size: 1em;
  transition: color 0.3s;
}

.Read-More-en:hover {
  color: black;
}

@media (max-width: 1200px) {
  .Blog-Body-en {
    padding: 30px 40px;
  }
}

@media (max-width: 768px) {
  .Blog-Body-en {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .Blog-Body-en {
    padding: 15px 10px;
  }

  .Blog-Title-en {
    font-size: 1.2em;
  }

  .Blog-Description-en {
    font-size: 0.9em;
  }

  .Blog-Grid-en {
    grid-template-columns: 1fr; /* Single column layout on small screens */
    gap: 10px;
  }
}
