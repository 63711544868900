#lang {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
}

#lang a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
}

#lang .en::before{
  display: none;
}

#lang .en::after{
  content: '▼'; /* Unicode character for a downward arrow */
  font-size: 10px;
  margin-left: 8px;
  transition: transform 0.3s ease-in-out;
}

#lang:hover a::after {
  transform: rotate(180deg); /* Rotate the arrow when hovered */
}

#lang .lang-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
  visibility: hidden;
  border-radius: 0 0 10px  10px ;
  padding: 5px;
}

#lang:hover .lang-dropdown {
  max-height: 40px; /* Adjust based on the content */
  visibility: visible;
}

#lang .lang-dropdown a {
  display: block;
  color: black;
  text-decoration: none;
  text-align: center;
}

#lang .lang-dropdown:hover {
  opacity: 0.5;
}

nav.scrolled #lang a{
  color: var(--main-color); /* Color when scrolled */
}

#Contact-btn{
  background-color: white;
  color: white;
  padding: 11px 20px;
  font-size: 15px;
}


@media (max-width: 1160px) {
  #lang {
    margin-left: 0;
    
  }
  #burger-menu {

    margin-left:0px;
    

  }
  #lang .lang-dropdown a {
    font-size:12px ;
  }

  #main-nav .burger-menu {
    display: block;
    cursor: pointer;
    order: 3;
    z-index: 1001; /* Ensure burger menu is on top */
  }
  #main-nav .logo {
    order: 2;
    transform: scale(0.8);
    margin: 0 auto; /* Center the logo horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #main-nav .lang {
    order: 1;
  }
}