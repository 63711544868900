.Page-Header {
  position: relative;
  padding: 0;
  margin: 0;
  height: 30%;
  overflow: hidden;
  background: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: 0.5s ease;
}

.page-header-spacer{
  padding-top: 100px;
}

.Page-Title-Wrapper{
  display: flex;
  overflow: hidden;
  background: rgb(36, 45, 45);
  border-radius: 30px   30px 0 0;
}

.Page-Title {
  color: var(--main-color);
  font-size: 30px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: relative;
}

.Page-Title::after{
  margin-left: 300px;
  margin-right: 15px;
}

.Page-Title::before{
  margin-left: 15px;
  margin-right: 300px;
}

.Page-Title::before,
.Page-Title::after {
  content: "";
  flex: 1;
  border-bottom: 3px solid var(--main-color);
 
}

.Page-Body{
  background-color: rgb(231, 231, 231);
}

.Page-Title-en::before{
  margin-left: 500px;
  margin-right: 20px;
}

.Page-Title-en::after{
  margin-left: 20px;
  margin-right: 500px;
}


@media (max-width: 800px) {
  /* .Page-Header {
    height: 20vh;
  } */
  .Page-Title {
    font-size: 1.5rem;
  }
  .Page-Title::before,
  .Page-Title::after {
    margin: 0 10px;
  }
}
