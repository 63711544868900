
.Footer-Body {
  position: relative; /* Ensure proper positioning for absolute children */
  background: url('../../../Assets/Images/earth.jpg') no-repeat center center;
  background-size: cover; 
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer-Body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.726); /* Semi-transparent black overlay */
  z-index: 0;
}

.Footer-Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around ;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  z-index: 1;
}

.Footer-Section-en {
  flex: 1 1 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  text-align: left; 
  
}

.services-footer-en{
  margin-left: 20px;
}

.Footer-img-Wrapper{
  max-width: 150px;
 
}

.Footer-img-Wrapper img{
  width: 100%;
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.815));
  transition: 0.3s ease;
}

.Footer-img-Wrapper img:hover{
  transform: scale(1.1);
}


.Footer-Section-en h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--main-color);
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.Footer-Section-en p,
.Footer-Section-en ul {
  margin: 0;
  font-size: 1em;
}

.Footer-Section-en ul {
  list-style: none;
  padding: 0;
}


.news-footer-en ul li a::after ,
.services-footer-en ul li .link::after ,
.links-footer-en ul li .link::after {
  content: '>'; /* Unicode left arrow character */
  margin-left: 5px; /* Space between arrow and text */
  transition: 0.3s ease;
  font-size: 18px;
}

.news-footer-en ul li a:hover::after,
.services-footer-en ul li a:hover::after,
.links-footer-en ul li a:hover::after {
  margin-left: 15px; /* Move the arrow slightly on hover */
}

.about-footer-en ul li {
  position: relative;
  padding-left: 30px; /* Adjust the padding to make space for the icon */
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.634);
}

.about-footer-en{
  
}

.about-footer-en ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: var(--main-color);
}

.Footer-Section-en ul li.email::before {
  content: '\f0e0'; /* Font Awesome envelope icon */
}

.Footer-Section-en ul li.phone::before {
  content: '\f095'; /* Font Awesome phone icon */
}

.Footer-Section-en ul li.location::before {
  content: '\f3c5'; /* Font Awesome map-marker-alt icon */
}

.Footer-Section-en ul li.question::before {
  content: '\f128'; /* Font Awesome map-marker-alt icon */
}


.Footer-Section-en ul li a {
  color: rgba(255, 255, 255, 0.634);
  text-decoration: none;
  transition: color 0.3s ease;
}

.Footer-Section-en ul li a:hover {
  color: #00bcd4;
}

.news-section-en h2{
  margin-bottom: 20px;
}

.Footer-Section-en .newsletter-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 300px; /* Adjust width as needed */
}

.Footer-Section-en .newsletter-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.Footer-Section-en .newsletter-input button {
  color: white;
  border: none;
  padding: 10px 22px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}







.Footer-Copyright p {
  margin: 0;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.575);
}

.Footer-Copyright {
  background-color: rgb(1, 41, 49);
  width: 100%;
  padding: 10px 50px;
  box-sizing: border-box;
}

.Footer-Copyright-Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.Social-Media-Buttons {
  display: flex;
}

.Social-Media-Buttons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: var(--main-color); 
  color: #f6f6f6; 
  text-decoration: none;
  transition: 0.3s ease, color 0.01s ease;
  font-size: 16px;
}

.Social-Media-Buttons a:hover {
  background-color: #f6f6f6; 
  color: var(--main-color); 
}


/* Responsive Design */
@media (max-width: 768px) {
  .Footer-Content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .services-footer-en{
    margin: 0;
  }

  .Footer-Section-en ul li.email::before {
    display: none;
   }
   
   .Footer-Section-en ul li.phone::before {
     display: none;
   }
   
   .Footer-Section-en ul li.location::before {
     display: none;
   }
   
   .Footer-Section-en ul li.question::before {
     display: none;
   }


   .Footer-Section-en {
    padding: 0 15px;
    text-align: start;
    max-width: 100%;
    align-self: start;
  }

  .services-footer-en{
    margin: 0;
   
  }

  
  .about-footer-en ul li {
    padding:0; 
  }
  
  .about-footer-en{
    margin: 0;
  }

  .about-footer-en img{
    max-width: 100px;
  }

  

  .Footer-Copyright-Content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .Footer-Copyright-Content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-footer-en{
    order: 2;
  }

  .links-footer-en{
    order: 4;
  }

  .services-footer-en{
    order: 3;
  }

  .news-section-en{
    order: 1;
  }

  .Social-Media-Buttons a {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }

  .Footer-Copyright p{
    font-size: 0.7rem;
    padding: 10px 0;
  }

  .Footer-Section .newsletter-input input {
    padding: 7px;
  }
  
  .Footer-Section .newsletter-input button {
    padding: 6px 22px;
  }
  
}
