
/* Blog Body Styles */
.Blog-Body {
  padding: 30px 50px;
  direction: rtl;
}

/* Blog Grid Styles */
.Blog-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1000px, 1fr));
  gap: 20px;
}

/* Blog Card Styles */
.Blog-Card {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.6s ease;
}

.Blog-Card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Blog Image Styles */
.Blog-Image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Blog Content Styles */
.Blog-Content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: 0.6s ease;
}

/* Blog Title Styles */
.Blog-Title {
  font-size: 1.5em;
  margin: 0 0 15px;
  flex-shrink: 0;
}

/* Blog Time Styles */
.Blog-Time {
  color: #999;
  font-size: 0.9em;
  margin: 0 0 10px;
  flex-shrink: 0;
}

/* Blog Description Styles */
.Blog-Description {
  font-size: 1em;
  margin: 0;
  color: #000000a1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 4.5em; /* 1.5em * 3 lines */
  transition: max-height 0.6s ease;
}

.Blog-Description.expanded {
  -webkit-line-clamp: unset;
  max-height: none; /* Allow full height */
}

/* Read More Button Styles */
.Read-More {
  background: none;
  border: none;
  color: var(--main-color);
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
  font-size: 1em;
  transition: color 0.3s;
}

.Read-More:hover {
  color: black;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .Blog-Body {
    padding: 30px 40px;
  }
}

@media (max-width: 768px) {
  .Blog-Body {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .Blog-Body {
    padding: 15px 10px;
  }

  .Blog-Title {
    font-size: 1.2em;
  }

  .Blog-Description {
    font-size: 0.9em;
  }

  .Blog-Grid {
    grid-template-columns: 1fr; /* Single column layout on small screens */
    gap: 10px;
  }
}
